import * as React from "react"
import { useState, useEffect } from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo"
const times = [0,46,67,130,198,306,318,352,387,413,471,506,533,564,598,611,617,635,654,670,691,717,749,799,816,845,867,889,932,942,968,1007,1022,1031,1048,1066,1096,1123,1142,1151,1171,1192,1253,1321,1330,1391,1417,1459,1563,1570,1621,1646,1678,1715,1729,1761,1786,1847,1934,1945,2015,2036,2085,2131,2188,2246,2271,2323,2355,2422,2525,2608]

const leftPad = (num) => {
  return String(num).padStart(2,'0')
}

const prettyTimes = (start = 0, end = times.length) => {
  const pretty = []
  for (const [i, time] of times.slice(start,end).entries()) {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    pretty.push((i+1)+'/'+leftPad(minutes)+':'+leftPad(seconds))
  }
  return pretty.join(', ')
}

const Presentation = ({ data, location }) => {
  const [expandSlides, setExpandSlides] = useState(false)
  let slidesText = <>{prettyTimes(0,3) + '...'}<a href="#" onClick={() => setExpandSlides(true)}>[+]</a></>
  if (expandSlides) {
    const showLess = <a href="#" onClick={() => setExpandSlides(false)}>[–]</a>
    slidesText = <>{showLess} {prettyTimes()} {showLess}</>
  }

  return (
    <>
      <div className="slides-manual">
        <div className="video"><iframe width="560" height="315" src="https://www.youtube.com/embed/aCadmiIfbcI?si=HPWsXloxvxeDEywu" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></div>
        <div className="slides"><iframe title="slides" src="https://docs.google.com/presentation/d/e/2PACX-1vRArbyOptLdyb6PdUAfnuuNA8oPk0MUJSyMqzBWkSXrPHnpBG0a8Q39LafB9ak8mz0nHp_VbK-h7VSj/embed?start=false&loop=false" frameBorder="0" width="960" height="749" allowFullScreen={true} mozallowfullscreen="true" webkitallowfullscreen="true"></iframe></div>
      </div>
      <div className="below-slides-manual">
      <div className="slides-text">slide start times: {slidesText}</div>
        <ul>
          <li>Discussion <a target="_blank" rel="noreferrer" href="https://news.ycombinator.com/item?id=33475391">on Hacker News</a></li>
          <li>Discussion <a target="_blank" rel="noreferrer" href="https://old.reddit.com/r/FreeSpeech/duplicates/yc8whv/revedditcom_improving_online_discourse_with/">on Reddit</a></li>
          <li>Twitter <a target="_blank" rel="noreferrer" href="https://twitter.com/rhaksw">@rhaksw</a></li>
          <li>Download the <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1IYAI8ZG-xp7PXlFJrsgo3Am2L10BjLNc/view?usp=sharing">slides</a></li>
        </ul>
      </div>
    </>
  )
}

export const Head = ({data}) => {
  return (
    <Seo
        description="A talk given for Truth and Trust Online on October 12th, 2022."
        imagepath='/talk-screenshot-1-1-ratio.png'
        title="Reveddit.com: Improving online discourse with transparent moderation. October 12, 2022"
    />
  )
}

export default Presentation
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
